// app-javascript-application.js

// Entry point for the build script in your package.json
//require("cocoon")
//require("./jquery.lazyload")
//si falla habilitar lo siguiente:
//require("./jquery")

import "@hotwired/turbo-rails"
// si la aplicación comienza a fallar  inactivar  solo la siguiente linea y compilar cambiar a "false"
//es turbo-drive no recarga por completo las paginas, solo el body el header no se refresca y opdria generar inconsistencias
Turbo.session.drive = false

import "./controllers"
// import * as bootstrap from "bootstrap"
import "./customjs"
//INICIA IMPOrT DE QUAGGA
/*import { setupScanner, iniciarEscaner } from "./quagga_scanner";

// Configura el escáner cuando el modal se muestra
document.addEventListener("turbo:frame-load", () => {
  console.log("Turbo Frame cargado");

    setupScanner();
});
*/
//FIN QUAGGA
// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// Script para poppover
//let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//    return new bootstrap.Popover(popoverTriggerEl)
//})
import "chartkick/chart.js"
import "./channels"

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
