import { Controller } from "@hotwired/stimulus";
import Quagga from "quagga";
import { setupScanner, iniciarEscaner } from "../quagga_scanner";

export default class extends Controller {
  static targets = ["interactive", "mensajeScanner"];

  connect() {
    console.log("QuaggaController conectado");
    // Escuchar evento personalizado
 document.addEventListener("scanner:stop", (event) => {
   this.detenerScanner(event.detail.mensaje);
 });
  }


   iniciarEscaner() {
    console.log("Reiniciando QuaggaJS con detección visual...");

    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: { facingMode: "environment" },
          target: document.querySelector("#interactive"),
        },
        decoder: {
          readers: ["ean_reader"],
        },
        locate: true,
        numOfWorkers: navigator.hardwareConcurrency || 4,
        frequency: 10,
        debug: true,
      },
      function (err) {
        if (err) {
          console.error("Error al iniciar QuaggaJS:", err);
          return;
        }
        Quagga.canvas.dom.overlay.style.display = "block";
        Quagga.start();
      }
    );

    // 🔥 Dibujar las cajas delimitadoras (Bounding Boxes)
    Quagga.onProcessed(function (result) {
      const drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

      if (result) {
        if (result.boxes) {
          result.boxes.forEach((box) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
              color: "blue",
              lineWidth: 2,
            });
          });
        }
        if (result.codeResult) {
          Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, {
            color: "green",
            lineWidth: 3,
          });
        }
      }
    });

    let lastScannedCodes = [];

    Quagga.onDetected(function (result) {
      const code = result.codeResult.code;
      console.log("Código detectado:", code);

      lastScannedCodes.push(code);
      if (lastScannedCodes.length > 3) {
        lastScannedCodes.shift();
      }
      const canvas = document.querySelector("#interactive canvas");
      if (canvas) {
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, ctx, {
            color: "green",
            lineWidth: 2,
          });
        }

        if (result.boxes) {
          result.boxes.forEach(function (box) {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, ctx, {
              color: "blue",
              lineWidth: 2,
            });
          });
        }
      }

      if (lastScannedCodes.length === 3 && new Set(lastScannedCodes).size === 1) {
        console.log("Código confirmado:", code);

        Quagga.stop();

        const barcodeInput = document.getElementById("search_item");
        barcodeInput.value = code;

        const form = barcodeInput.closest("form");
        form.requestSubmit(); // 🔥 Enviamos el formulario
      }
    });
  }

  stopScanner() {
    console.log("Deteniendo escáner...");
    Quagga.stop();
  }

  // Muestra un mensaje temporal en el modal
  mostrarMensaje(event) {
    const messageElement = this.mensajeScannerTarget;
    const interactiveElement = this.interactiveTarget;

    if (messageElement) {
      messageElement.innerHTML = `<div class="alert alert-success text-center">Código escaneado correctamente</div>`;
    }

    interactiveElement.style.display = "none"; // Ocultar la cámara

    setTimeout(() => {
      if (messageElement) {
        messageElement.innerHTML = ""; // Limpiar el mensaje
      }
      interactiveElement.style.display = "block"; // Mostrar la cámara nuevamente
      this.iniciarEscaner(); // Reactivar el escáner
    }, 1500);
  }
  detenerScanner(mensaje) {
    console.log("Deteniendo escáner...");
    Quagga.stop();

    const messageElement = this.hasMensajeScannerTarget
      ? this.mensajeScannerTarget
      : null;
    const interactiveElement = this.hasInteractiveTarget
      ? this.interactiveTarget
      : null;

    if (messageElement) {
      messageElement.innerHTML = `${mensaje}`;
    }

    if (interactiveElement) {
      interactiveElement.style.display = "none";

      setTimeout(() => {
        if (messageElement) {
          messageElement.innerHTML = "";
        }
        interactiveElement.style.display = "block";
        this.iniciarEscaner(); // Reactivar el escáner
      }, 1500);
    }
  }

}
