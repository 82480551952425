// app/javascript/scanners/quagga_scanner.js
import Quagga from "quagga";
//window.Quagga = Quagga; // Hacer que Quagga sea accesible globalmente

console.log("Cargando quagga_scanner.js");

export function setupScanner() {
  //if (window.setupScannerExecuted) {
  //console.log("setupScanner ya ha sido ejecutado");
  //return; // Si ya se ejecutó, no hacer nada
//}

//window.setupScannerExecuted = true; // Marca que setupScanner ha sido ejecutado

  console.log("setupScanner ha sido llamado");

  console.log("Configurando escáner...");

  const barcodeInput = document.getElementById("search_item");

  // Verifica si barcodeInput existe
  if (!barcodeInput) {
    console.warn("No se encontró el campo de entrada de código de barras");
    return;
  }

  const form = barcodeInput.closest("form");

  // Verifica si el formulario existe
  if (!form) {
    console.warn("No se encontró el formulario");
    return;
  }

  const scannerModal = document.getElementById("scannerModal");
  scannerModal.addEventListener("shown.bs.modal", function () {
    iniciarEscaner();
  });

  scannerModal.addEventListener("hidden.bs.modal", function () {
    Quagga.stop();
  });

  document.getElementById("stop-scanner").addEventListener("click", function () {
    Quagga.stop();
  });
}

export function iniciarEscaner() {
  console.log("Reiniciando QuaggaJS con detección visual...");

  Quagga.init(
    {
      inputStream: {
        type: "LiveStream",
        constraints: { facingMode: "environment" },
        target: document.querySelector("#interactive"),
      },
      decoder: {
        readers: ["ean_reader"],
      },
      locate: true,
      numOfWorkers: navigator.hardwareConcurrency || 4,
      frequency: 10,
      debug: true,
    },
    function (err) {
      if (err) {
        console.error("Error al iniciar QuaggaJS:", err);
        return;
      }
      Quagga.canvas.dom.overlay.style.display = "block";
      Quagga.start();
    }
  );

  // 🔥 Dibujar las cajas delimitadoras (Bounding Boxes)
  Quagga.onProcessed(function (result) {
    const drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;

    drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

    if (result) {
      if (result.boxes) {
        result.boxes.forEach((box) => {
          Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
            color: "blue",
            lineWidth: 2,
          });
        });
      }
      if (result.codeResult) {
        Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, {
          color: "green",
          lineWidth: 3,
        });
      }
    }
  });

  let lastScannedCodes = [];

  Quagga.onDetected(function (result) {
    const code = result.codeResult.code;
    console.log("Código detectado:", code);

    lastScannedCodes.push(code);
    if (lastScannedCodes.length > 3) {
      lastScannedCodes.shift();
    }
    const canvas = document.querySelector("#interactive canvas");
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, ctx, {
          color: "green",
          lineWidth: 2,
        });
      }

      if (result.boxes) {
        result.boxes.forEach(function (box) {
          Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, ctx, {
            color: "blue",
            lineWidth: 2,
          });
        });
      }
    }

    if (lastScannedCodes.length === 3 && new Set(lastScannedCodes).size === 1) {
      console.log("Código confirmado:", code);

      Quagga.stop();

      const barcodeInput = document.getElementById("search_item");
      barcodeInput.value = code;

      const form = barcodeInput.closest("form");
      form.requestSubmit(); // 🔥 Enviamos el formulario
    }
  });
}
